import request from '../request.js'

export const list = params => {
	return request({
		url: "/user/list/",
		method: "get",
		params
	})
}

export const add = data => {
	return request({
		url: "/user/add/",
		method: "post",
		data
	})
}

export const del = ids => {
	if (ids instanceof Array) {
		ids = ids.join(',')
	}
	return request({
		url: "/user/delete/",
		method: "post",
		data: {
			ids
		}
	})
}

export const edit = data =>{
	return request({
		url:"/user/edit/",
		method:"post",
		data
	})
}

export const updatePass = data=>{
	return request({
		url:"/user/update/password/",
		method:"post",
		data
	})
}

export const updateSelfPass = data=>{
	return request({
		url:"/user/update/user_password/",
		method:"post",
		data
	})
}
