<template>
	<div class="role-contianer">
		<div class="my-form-container">
			<el-form :inline="true" :model="searchForm" class="my-form">
				<el-form-item label="账号名称：">
					<el-input v-model="searchForm.username" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="姓名：">
					<el-input v-model="searchForm.last_name" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="邮箱：">
					<el-input v-model="searchForm.email" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="部门：">
					<el-select v-model="searchForm.department_id" placeholder="请选择">
						<el-option label="全部" value="">
						</el-option>
						<el-option v-for="item in departmentList" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="角色：">
					<el-select v-model="searchForm.role_id" placeholder="请选择">
						<el-option label="全部" value="">
						</el-option>
						<el-option v-for="item in roleList" :key="item.role_id" :label="item.role_name"
							:value="item.role_id">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div class="form-button">
				<el-button style="background-color: #3B91FF; color: #FFFFFF;" @click="getDataSearch()">查询</el-button>
				<el-button style="background-color: #FFFFFF; color: #666666;" @click="searchForm = {}">重置</el-button>
			</div>
		</div>
		<div class="my-table">
			<div style="display: flex;">
				<div class="invite-button"
					style="margin-left: 1.7rem; margin-bottom: .8rem;border-radius: .2rem;border: 1px solid #3B91FF;"
					@click="openAdd(1)">
					<img src="../../assets/uid/jiahao.png">
					新增人员
				</div>
			</div>
			<el-table :data="tableData" style="width: 100%;" empty-text="抱歉，暂无数据！">
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column fixed prop="username" label="账号名称" min-width="180">
				</el-table-column>
				<el-table-column prop="last_name" label="姓名" min-width="150">
				</el-table-column>
				<el-table-column prop="email" label="邮箱" width="180">
				</el-table-column>
				<el-table-column prop="department" label="部门" min-width="120">
				</el-table-column>
				<el-table-column prop="role" label="角色" min-width="120">
				</el-table-column>
				<el-table-column prop="status" label="状态" min-width="120">
					<template slot-scope="scope">
						<div class="free-dj">
							<div :class="{'free':scope.row.status == 0,'dj':scope.row.status == 9||scope.row.status == 1}"></div>
							{{scope.row.status == 0?'正常':scope.row.status == 9?'删除':'冻结'}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="date_joined" label="创建时间" min-width="150">
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="202">
					<template slot-scope="scope">
						<el-button type="text" size="small" @click="openAdd('2',scope.row)">人员管理</el-button>
						<el-button type="text" size="small" @click="openUpdatePass(scope.row)">修改密码</el-button>
						<el-button style="color: #FF4D4F;" @click="deleteUserDialog = true;delId = scope.row.id"
							type="text" size="small">
							删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="my-page">
				<el-pagination background layout="prev, pager, next, sizes, jumper" @size-change="changeSize"
					:total="total" :current-page="searchForm.page" @current-change="changePage">
				</el-pagination>
			</div>
		</div>
		<el-dialog class="my-dialog invite-dialog" :title="dialogTitle" :visible.sync="addUserDialog" width="30%"
			center>
			<el-form class="my-dialog invite-dialog" ref="userForm" :model="userForm" :rules="userFormRules"
				label-width="7.8rem">
				<el-form-item label="账号名称：" prop="username">
					<el-input v-model="userForm.username" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="姓名：" prop="last_name">
					<el-input v-model="userForm.last_name" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="邮箱：" prop="email">
					<el-input v-model="userForm.email" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="部门：" prop="department_id">
					<el-select v-model="userForm.department_id" placeholder="请选择">
						<el-option v-for="item in departmentList" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="角色：" prop="role_id">
					<el-select v-model="userForm.role_id" placeholder="请选择" filterable>
						<el-option v-for="item in roleList" :key="item.role_id" :label="item.role_name"
							:value="item.role_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="状态：" prop="status">
					<el-select v-model="userForm.status" placeholder="请选择">
						<el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="初始密码：" prop="password" v-if="openAddType == 1">
					<el-input :disabled="true" v-model="userForm.password" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="创建时间：" prop="date_joined" v-if="openAddType != 1">
					<span style="color: #111111; font-size: 14px;">{{userForm.date_joined}}</span>
				</el-form-item>
			</el-form>
			<span slot="footer" class="my-dialog-footer">
				<el-button @click="addUserDialog = false">取 消</el-button>
				<el-button type="primary" @click="commitAddUser">{{openAddType == 1?'确定新增':'确定'}}</el-button>
			</span>
		</el-dialog>
		<el-dialog class="my-dialog invite-dialog" title="修改密码" :visible.sync="updatePasswordDialog" width="30%" center>
			<el-form class="my-dialog invite-dialog" ref="passForm" :model="passForm" :rules="passRule"
				label-width="7.8rem">
				<el-form-item label="修改密码：" prop="password">
					<!-- <el-input type="password" show-password placeholder="请输入"></el-input> -->
					<el-input placeholder="密码" :type="!showPass?'password':'text'"
						v-model="passForm.password">
						<img src="../../assets/login/showpass.png" slot="suffix"
							style="width: 18px; height: 18px;margin-right: .3rem;cursor: pointer;"
							@click="showPass = true" v-if="!showPass">
						<img src="../../assets/login/hidepass.png" slot="suffix"
							style="width: 18px; height: 18px;margin-right: .3rem;cursor: pointer;"
							@click="showPass = false" v-if="showPass">
					</el-input>
				</el-form-item>
				<el-form-item label="确认修改密码：" prop="confirm_password">
					<el-input placeholder="确认修改密码" :type="!showConPass?'password':'text'"
						v-model="passForm.confirm_password">
						<img src="../../assets/login/showpass.png" slot="suffix"
							style="width: 18px; height: 18px;margin-right: .3rem;cursor: pointer;"
							@click="showConPass = true" v-if="!showConPass">
						<img src="../../assets/login/hidepass.png" slot="suffix"
							style="width: 18px; height: 18px;margin-right: .3rem;cursor: pointer;"
							@click="showConPass = false" v-if="showConPass">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="my-dialog-footer">
				<el-button @click="updatePasswordDialog = false">取 消</el-button>
				<el-button type="primary" @click="commitUpdatePass()">确认</el-button>
			</span>
		</el-dialog>
		<el-dialog class="my-dialog delete-dialog" title="删除账号" :visible.sync="deleteUserDialog" width="30%" center>
			<div class="attention">
				<img src="../../assets/attention.png">
				此操作不可逆，请谨慎确认！！
			</div>
			<span slot="footer" class="my-dialog-footer">
				<el-button @click="deleteUserDialog = false">取 消</el-button>
				<el-button type="danger" class="error-button" @click="commitDel()">确定删除</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		list,
		add,
		del,
		edit,
		updatePass
	} from '../../api/user.js'
	import {
		list as roleList
	} from '../../api/role.js'
	import mixin from '../../mixins.js'
	export default {
		mixins: [mixin],
		data() {
			var validateConPass = (rule, value, callback) => {
				console.log('email' + value)
				if (value == "" || !value) {
					callback(new Error('请确认密码'));
				} else {
					if (value != this.passForm.password) {
						// this.$refs.ruleForm.validateField('checkPass');
						callback(new Error('两次输入的密码不一致'));
					} else {
						callback();
					}
				}
			};
			var validateEmail = (rule, value, callback) => {
				console.log('email' + value)
				if (value == "" || !value) {
					console.log('请输入邮箱')
					callback(new Error('请输入邮箱'));
				} else {
					if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
						// this.$refs.ruleForm.validateField('checkPass');
						callback(new Error('请输入正确的邮箱'));
					} else {
						callback();
					}
				}
			};
			return {
				deleteUserDialog: false,
				dialogTitle: "",
				addUserDialog: false,
				userForm: {},
				updatePasswordDialog: false,
				showConPass: false,
				showPass: false,
				userFormRules: {
					username: [{
						required: true,
						message: '请输入',
						trigger: 'change'
					}],
					last_name: [{
						required: true,
						message: '请输入',
						trigger: 'change'
					}],
					email: [{
						required: true,
						validator: validateEmail,
						trigger: 'change'
					}],
					department_id: [{
						required: true,
						message: '请输入',
						trigger: 'change'
					}],
					role_id: [{
						required: true,
						message: '请输入',
						trigger: 'change'
					}],
					status: [{
						required: true,
						message: '请输入',
						trigger: 'change'
					}],
					password: [{
						required: true,
						message: '请输入',
						trigger: 'change'
					}],
				},
				delId: "",
				openAddType: 1,
				roleList: [{
					value: 0,
					label: "超级管理员"
				}],
				departmentList: [{
					value: 1,
					label: "线上团队"
				}, {
					value: 2,
					label: "线下团队"
				}, {
					value: 3,
					label: "财务部"
				}],
				statusList: [{
					value: 0,
					label: "正常"
				}, {
					value: 1,
					label: "冻结"
				}, {
					value: 9,
					label: "删除"
				}],
				passForm: {},
				passRule: {
					password: [{
						required: true,
						message: '请输入',
						trigger: 'change'
					}],
					confirm_password: [{
						required: true,
						validator: validateConPass,
						trigger: 'change'
					}],
				}
			}
		},
		computed: {

		},
		methods: {
			async getData() {
				let res = (await list(this.searchForm)).data
				this.tableData = res.records
				this.total = res.total_count
			},
			openAdd(i, row) {
				this.openAddType = i
				if (i == 1) {
					this.dialogTitle = '新增人员'
					this.userForm = {}
					this.userForm.password = "88888888"
				}
				if (i == 2) {
					this.dialogTitle = '人员管理'
					this.userForm = JSON.parse(JSON.stringify(row))
					// this.userForm = (row)
				}
				this.addUserDialog = true
			},
			openUpdatePass(row) {
				console.log(row)
				this.passForm = {}
				this.passForm.target_id = row.id
				this.updatePasswordDialog = true
			},
			commitAddUser() {
				this.$refs["userForm"].validate(async (valid) => {
					if (valid) {
						this.userForm.role = ""
						if (this.openAddType == 1) {
							await add(this.userForm)
							this.$message.success('新增成功')
						} else if (this.openAddType == 2) {
							await edit(this.userForm)
							this.$message.success('修改成功')
						} else if (this.openAddType == 3) {
							this.$message.success('修改成功')
						}
						this.addUserDialog = false
						this.getData()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			commitUpdatePass() {
				this.$refs["passForm"].validate(async (valid) => {
					if (valid) {
						await updatePass(this.passForm)
						this.$message.success('修改成功')
						this.updatePasswordDialog = false
						this.getData()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			async commitDel() {
				await del(this.delId)
				this.$message.success('删除成功')
				this.deleteUserDialog = false
				this.getData()
			}
		},
		created() {
			roleList({}).then(res => {
				console.log(res)
				this.roleList = res.data.records
			})
		}
	}
</script>

<style lang="scss">
	.role-contianer {
		.copy {
			background-color: #ffffff;
			color: #3B91FF;
			border: 1px solid #3B91FF;
		}

		.free-dj {
			display: flex;
			align-items: center;

			div {
				width: 6px;
				height: 6px;
				border-radius: 50%;
				margin-right: .4rem;
				flex-shrink: 0;
			}

			.free {
				background-color: #74CB86;
			}

			.dj {
				background-color: #FF4D4F;
			}
		}

		.attention {
			margin-top: 2.5rem;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: .8rem;
				height: .8rem;
				margin-right: .4rem;
			}
		}
		.el-input__prefix, .el-input__suffix{
			display: flex;
			align-items: center;
		}
	}
</style>
